.section-white {
    background: #fff;
    padding: 70px 0;
}

.team-item {
    display: inline-block; /* Display as inline block */
    width: calc(33.33% - 40px); /* Set width to one-third minus margin */
    margin: 20px; /* Add margin between cards */
    vertical-align: top; /* Align cards to the top */
    background: #f4f5f6;
    text-align: center;
    padding: 50px 20px 40px 20px;
    border-radius: 8px;
    transition: all 0.25s ease-in-out;
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
}


.team-item:hover{
    background: #3956de;
    color: white;
}

.team-item h3{
    margin:20px 0 1px 0!important;
    color:#454545;
    font-size:18px;
    font-weight: 700;
    text-transform:uppercase;
    transition: all .25s ease-in-out;
    -moz-transition: all .25s ease-in-out;
    -webkit-transition: all .25s ease-in-out;
}

.section-subtitle {
    white-space: pre-line;
}

.team-item:hover h3{
    color:#ddd;
}

.team-info {
    display: block;
    margin-bottom:0;
}

.team-info p{
    color:#acacac;
    font-style:italic;
}

.team-info::after {
    background: #008aff;
    background: -webkit-linear-gradient(135deg, #6B02FF 0%, #985BEF 100%);
    background: -o-linear-gradient(bottom right, #6B02FF, #985BEF);
    background: -moz-linear-gradient(bottom right, #008aff, #1ad2fd);
    background: linear-gradient(135deg, #6B02FF 0%, #985BEF 100%);
    display: inline-block;
    vertical-align: middle;
    content: "";
    width: 50px;
    height: 3px;
}

.row {
    display: flex;
    flex-wrap: wrap; /* Allow cards to wrap to the next line */
    justify-content: center; /* Center cards horizontally */
}

.team-img{
    max-width: 140px;
    padding: 6px 6px;
    background-color: #dedede;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

ul.team-icon {
    margin-top: 25px;
}

ul.team-icon li{
    display: inline-block;
    margin:0 3px;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}

ul.team-icon li a{
    margin: 0;
    display: inline-block;
    padding: 8px 8px;
    width: 34px;
    height: 34px;
    font-size: 18px;
    line-height: 18px;
    background-color: #fff;
    border-radius: 50%;
    color:#fff;
}

ul.team-icon li a.Linkedin{
    background-color: #12ea7f;
}

ul.team-icon li a.twitter{
    background-color: #4099ff;
}

ul.team-icon li a.pinterest{
    background-color: #C92228;
}

ul.team-icon li a.facebook{
    background-color: #3b5998;
}

ul.team-icon li a.dribble{
    background-color: #ea4c89;
}

.team-icon li a:hover{
    color:#dedede;
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -ms-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}
